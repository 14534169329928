import ExcelJS from 'exceljs'
frappe.provide('frappe.Repair');
$.extend(frappe.Repair, {
    export_report: function(options) {
		const columns =options.columns.map(function(item){
			return {"name":item.label,"filterButton":true}
		});	
		const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Repair Note Dashboard');
    
        // Convert your data into rows format required by ExcelJS
        const rows = options.data.map(function(item){ return Object.values( item) });
        // Add table to the worksheet
        worksheet.addTable({
            name: 'RepairNoteTable',
            ref: 'A1',
            headerRow: true,
            totalsRow: false,
            style: {
                theme: 'TableStyleLight9',
                showRowStripes: true,
            },
            columns: columns,
            rows: rows,
        });
        // Generate buffer and create a Blob to trigger download
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = options.filename || 'Repair Note Dashboard.xlsx';
            a.click();
            URL.revokeObjectURL(url);
        }).catch(err => {
            console.error('Error exporting to Excel:', err);
        });
	}
});